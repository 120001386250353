import styled from "styled-components";

export const Container = styled.section`
  width: 100vw;
  height: 100vh;
  background: #191919;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;

    .left {
      img {
        width: 650px;
      }
    }

    .right {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      gap: 2px;

      h1 {
        color: white;
        font-weight: 800;
        font-size: 2.9rem;
      }

      p {
        width: 500px;
        color: #fff;
        font-size: 1.2rem;
        font-weight: 300;
        text-align: left;
      }

      button {
        width: 120px;
        height: 60px;
        cursor: pointer;
        border-radius: 30px;
        border: none;
        margin-top: 8px;
      }
    }
  }

  @media screen and (max-width: 1250px) {
    .right {
      p {
        font-size: 0.8rem;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    .container {
      gap: 35px;

      .right {
        width: 400px;

        h1 {
          font-size: 1.4rem;
        }

        p {
          max-width: 100%;
          word-wrap: break-word;
          font-size: 0.8rem;
        }
        img {
          width: 420px;
          height: 220px;
        }
      }
    }
    @media screen and (max-width: 1150px) {
      .container {
        gap: 50px;

        .right {
          width: 300px;

          p {
            max-width: 100%;
            word-wrap: break-word;
          }
        }
        img {
          width: 50vw;
        }
      }
      @media screen and (max-width: 1000px) {
        .container {
          gap: 30px;

          .left {
            img {
              width: 45vw;
            }
          }

          .right {
            width: 300px;

            p {
              max-width: 100%;
              word-wrap: break-word;
              font-size: 1rem;
            }

            h1 {
              font-size: 1.5rem;
            }
          }
          img {
            width: 55vw;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;
