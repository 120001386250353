import React, { useRef } from "react";
import {
  Container,
  FormWrapper,
  FormGroup,
  Label,
  Input,
  Select,
  Textarea,
  Button,
  Header,
} from "./StyledForm";
import emailjs from "@emailjs/browser";

function ContactForm() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_z48xgvi",
        "template_htwnzi8",
        form.current,
        "EG9I2xwgwAORLnm7E"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message envoyé");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <Container>
      <Header>Parlez-nous de votre projet 🏗️</Header>
      <FormWrapper>
        <form ref={form} onSubmit={sendEmail}>
          <FormGroup>
            <Label htmlFor="nomPrenom">
              Commençons par faire connaissance :
            </Label>
            <Input
              type="text"
              name="user_name"
              id="nomPrenom"
              required
              placeholder="Votre nom complet"
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="contactInfo">
              Comment peut-on vous contacter ?
            </Label>
            <div>
              <Input
                type="email"
                name="user_email"
                id="email"
                required
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}"
                placeholder="Email"
              />
              <Input
                type="number"
                name="user_phone"
                id="telephone"
                required
                pattern="0[1-9](?:\d{2}){4}"
                placeholder="Numéro de téléphone (ex: 0123456789)"
              />
            </div>
          </FormGroup>

          <FormGroup>
            <Label htmlFor="budget">Budget du projet :</Label>
            <Select name="user_budget" id="budget" required>
              <option value="">Sélectionnez un budget</option>
              <option value="700-2000">Entre 700€ et 2000€</option>
              <option value="2000-3500">Entre 2000€ et 3500€</option>
              <option value="3500+">3500€ et plus</option>
            </Select>
          </FormGroup>

          <FormGroup>
            <Label htmlFor="projetExplication">Expliquez votre projet :</Label>
            <Textarea
              name="user_message"
              id="projetExplication"
              rows="4"
              required
            />
          </FormGroup>

          <FormGroup>
            <Button type="submit">Envoyer</Button>
          </FormGroup>
        </form>
      </FormWrapper>
    </Container>
  );
}

export default ContactForm;
