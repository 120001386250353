import styled from "styled-components";

export const Container = styled.section`
  width: 100vw;
  height: 130vh;
  padding: 15px;
  background: #191919;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    font-size: 2.8rem;
    font-weight: 700;
    text-align: center;
    width: 550px;
    line-height: 1.1;
    overflow: hidden;
    color: #fff;
  }

  p {
    color: white;
    opacity: 0.5;
    font-weight: 200;
  }

  .accordion {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    margin-top: 35px;
  }

  .accordion-item {
    padding: 8px;
    border: 1px solid #fff;
    width: 60vw;
    border-radius: 10px;
    cursor: pointer;

    h2 {
      font-weight: 300;
      font-size: 1.2rem;
      color: #fff;
    }

    .accordion-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 2rem;
        padding: 10px;
        color: #fff !important;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;
