import React from "react";
import { Container } from "./StyledInformations";
import { GoArrowUpRight } from "react-icons/go";

const InformationsSections = () => {
  return (
    <Container>
      <div className="group-card">
        <div className="big-card">
          <div className="all">
            <h1>
              Les divers avantages d'un site internet <span>🚀</span>
            </h1>
            <p>
              Un site internet offre une <span>visibilité 24/7</span>,
              permettant à votre entreprise d'être <span>accessible</span> à
              tout moment, partout dans le monde.
              <br /> Vous pouvez présenter vos services en détail, faciliter la
              communication avec les clients grâce à des formulaires de contact,
              et renforcer la <span>crédibilité</span> de votre entreprise grâce
              à une présence professionnelle en <span>ligne</span>.<br /> <br />{" "}
              De plus, un site bien <span>optimisé</span> améliore votre
              <span>classement</span> dans les moteurs de recherche,{" "}
              <span>attirant</span>
              ainsi davantage de <span>clients potentiels</span>.
              <br /> En investissant dans un site internet, vous restez
              compétitif sur le marché en constante évolution et ouvrez la porte
              à une clientèle <span>internationale</span>.
            </p>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfN575MRBRqViKoN0XP0C0ta_UB0mz48O8_nN273IRRoCDFSw/viewform?usp=sf_link">
              <button className="spe">Nous contacter</button>
            </a>
          </div>
        </div>
        <div className="header">
          <div className="card">
            <div className="top">
              <h2>Nous choisir </h2>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSfN575MRBRqViKoN0XP0C0ta_UB0mz48O8_nN273IRRoCDFSw/viewform?usp=sf_link">
                <div className="icon">
                  <GoArrowUpRight />
                </div>
              </a>
            </div>
            <p>
              Dans notre agence, nous comprenons que chaque projet est unique,
              et c'est pourquoi nous sommes fiers d'offrir une flexibilité
              totale pour répondre à vos besoins. Que vous recherchiez la
              création d'un logo percutant, un site web ,un design de site web
              attractif, une refonte de site existant ou le développement d'une
              application personnalisée, nous avons l'expertise et la passion
              pour concrétiser votre vision. Notre équipe est prête à relever
              tous les défis créatifs et techniques, alors n'hésitez pas à nous
              faire part de votre projet. Votre succès est notre priorité, et
              nous sommes là pour vous accompagner à chaque étape de votre
              parcours numérique.
            </p>
          </div>

          <div className="card">
            <div className="top">
              <h2>Méthodologie </h2>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSfN575MRBRqViKoN0XP0C0ta_UB0mz48O8_nN273IRRoCDFSw/viewform?usp=sf_link">
                <div className="icon">
                  <GoArrowUpRight />
                </div>
              </a>
            </div>
            <p>
              Chez Platsel, nous avons élaboré un processus en trois étapes pour
              garantir le succès de chaque projet. <br /> <br />
              Étape 1 : Le Design Notre processus commence par une phase de
              design approfondie, où nous créons des concepts visuels sur mesure
              en collaboration avec vous.
              <br /> <br /> Étape 2 : Le Développement Une fois le design
              approuvé, nos développeurs chevronnés utilisent les technologies
              les plus récentes pour donner vie à votre projet, en veillant à la
              qualité du code et à la performance. <br /> <br />
              Étape 3 : La Méthodologie Agile Nous adoptons la méthodologie
              Agile pour des itérations rapides, des ajustements en cours de
              route et une collaboration étroite. Votre feedback est essentiel à
              chaque étape.
              <br /> <br /> Chez Platsel, nous sommes déterminés à offrir des
              solutions de qualité qui contribuent au succès de nos clients.
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default InformationsSections;
