import styled from "styled-components";

// Container div pour envelopper le formulaire
export const Container = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  height: 100vh;
  justify-content: center;
  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

// En-tête du formulaire
export const Header = styled.h2`
  font-size: 34px;
  font-weight: 900;
  margin-bottom: 20px;
  text-align: center;
`;

// Wrapper pour le formulaire
export const FormWrapper = styled.div`
  max-width: 70%; /* Ajustez la largeur selon vos besoins */
  width: 100%;
  margin: 0 auto; /* Centre le formulaire horizontalement */
`;

// Style pour chaque groupe de formulaire (label + input/select/textarea)
export const FormGroup = styled.div`
  margin-bottom: 15px;
`;

// Style pour les étiquettes (labels)
export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: px;
  /* font-size: clamp(1.3rem, 3.5vw, 1rem); */
  /* font-weight: 600; */
`;

// Style pour les champs de texte (input, select, textarea)
export const Input = styled.input`
  width: 100%;
  padding: 15px 10px; /* Augmente la hauteur du champ de texte */
  border: none; /* Supprime toutes les bordures */
  border-bottom: 1px solid #ccc; /* Ajoute une bordure uniquement en bas */
  font-size: 18px; /* Augmente la taille de la police */
  outline: none;
  margin-top: 10px;
`;

export const Select = styled.select`
  width: 100%;
  padding: 15px 10px; /* Augmente la hauteur du champ de texte */
  border: none; /* Supprime toutes les bordures */
  border-bottom: 1px solid #ccc; /* Ajoute une bordure uniquement en bas */
  font-size: 18px; /* Augmente la taille de la police */
`;

export const Textarea = styled.textarea`
  width: 100%;
  padding: 15px 10px; /* Augmente la hauteur du champ de texte */
  border: none; /* Supprime toutes les bordures */
  border-bottom: 1px solid #ccc; /* Ajoute une bordure uniquement en bas */
  font-size: 18px; /* Augmente la taille de la police */
  resize: none;
`;

// Style pour le bouton "Envoyer"
export const Button = styled.button`
  background-color: #191919;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  width: 100%; /* Pour occuper toute la largeur */
`;
