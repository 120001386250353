import styled from "styled-components";

export const Container = styled.section`
  width: 100vw;
  height: 125vh;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .group-card {
    max-width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
    overflow: hidden;
    margin-bottom: 120px;
  }

  .little-card {
    display: flex;
    gap: 60px;
  }

  .card {
    max-width: 500px;
    height: 450px;
    padding: 30px;
    border-radius: 40px;
    color: white !important;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    overflow: hidden;
    background: linear-gradient(
      90deg,
      rgba(252, 100, 66, 1) 0%,
      rgba(240, 35, 96, 1) 35%,
      rgba(179, 3, 102, 1) 65%,
      rgba(115, 8, 90, 1) 100%
    );

    h2 {
      font-size: 1.8rem;
      width: 200px;
      padding: 8px;
    }

    button {
      background: #191919;
      color: white;
      padding: 10px;
      border: none;
      border-radius: 40px;
      width: 90px;
      cursor: pointer;
      overflow: hidden;
    }

    p {
      opacity: 0.7;
    }

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 85%;
      padding: 10px;
      overflow: hidden;

      .icon {
        font-size: 1.5rem;
        color: white;
        font-weight: 600;
        background: #191919;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }

  .big-card {
    width: 100%;
    height: 550px;
    border-radius: 0%;
    display: flex;
    align-items: center;
    justify-items: center;
    text-align: center;

    .all {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      gap: 45px;

      h1 {
        color: #191919;
        font-size: clamp(1.3rem, 3vw, 5rem);
        line-height: 1;
        font-weight: 900;
        margin-top: 25px;
        font-size: 2.2rem;
        overflow: hidden;
        padding: 10px;
        text-align: center;
        margin-bottom: 30px;
      }

      span {
        margin-left: 4px;
      }

      p {
        font-size: 1rem;
        color: white;
        font-weight: 300;
        width: 88%;
        line-height: 1.2;
        overflow: hidden;
        text-align: left;
        margin-top: 20px;
      }

      button {
        width: 150px;
        height: 50px;
        color: white;
        border: 1px solid #fff;
        border-radius: 0px;
        background: transparent;
        cursor: pointer;
        overflow: hidden;
        background: linear-gradient(
          90deg,
          rgba(252, 100, 66, 1) 0%,
          rgba(240, 35, 96, 1) 35%,
          rgba(179, 3, 102, 1) 65%,
          rgba(115, 8, 90, 1) 100%
        );
        background-size: 100%;
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
        font-weight: 400;
      }

      span {
        background: linear-gradient(
          90deg,
          rgba(252, 100, 66, 1) 0%,
          rgba(240, 35, 96, 1) 35%,
          rgba(179, 3, 102, 1) 65%,
          rgba(115, 8, 90, 1) 100%
        );
        background-size: 100%;
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
        font-weight: 900;
      }
    }

    img {
      width: 45%;
    }

    .right {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding: 8px;
      gap: 15px;

      .btn-grp {
        display: flex;
        align-items: center;
        gap: 5px;

        button {
          width: 100px;
          height: 40px;
          border-radius: 0px;
          border: none;
          overflow: hidden;
        }
      }

      h1 {
        color: white;
        font-size: 2rem;
        max-width: 90%;
        line-height: 1.1;
        font-weight: 800;
        overflow: hidden;
        text-align: center;

        span {
          background-color: red;
          background: linear-gradient(
            90deg,
            rgba(252, 100, 66, 1) 0%,
            rgba(240, 35, 96, 1) 35%,
            rgba(179, 3, 102, 1) 65%,
            rgba(115, 8, 90, 1) 100%
          );
          background-size: 100%;
          background-repeat: repeat;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-background-clip: text;
          -moz-text-fill-color: transparent;
        }
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .block-1 {
    width: 30vw;
    height: 50vh;
    border-radius: 30px;

    img {
      width: 50vw;
      height: 50vh;
    }
  }
  .block-2 {
    width: 30vw;
    height: 50vh;
    border-radius: 30px;

    h3 {
      display: flex;
      font-size: 24px;
    }
    .block-2-button-1 {
      background-color: yellow;
      width: 7vw;
      height: 5vh;
      margin: 20px;
      border-radius: 20px;
      border: none;
    }
    .block-2-center {
      display: flex;
      justify-content: space-between;
      padding: 20px;
    }
    p {
      margin: 20px;
    }
  }

  @media screen and (max-height: 985px) {
    height: 150vh;
    .big-card {
      width: 90%;
      height: 100%;
      padding: 34px;
    }
  }

  @media screen and (max-width: 924px) {
    .big-card {
      width: 100%;
      height: 550px;
      background: #191919;
      border-radius: 0%;
      display: flex;
      align-items: center;
      justify-items: center;
      text-align: center;

      .all {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        gap: 45px;

        h1 {
          color: white;
          font-size: clamp(1.3rem, 3vw, 5rem);
          line-height: 1;
          font-weight: 900;
          margin-top: 25px;
          font-size: 1.8rem;
          overflow: hidden;
          padding: 10px;
          text-align: center;
        }
      }
    }
  }

  @media screen and (max-width: 1090px) {
    .card {
      h2 {
        font-size: 1.2rem;
      }
      p {
        font-size: 0.6rem;
        max-width: 290px;
        height: 300px;
        overflow: hidden;
      }

      .top {
        padding: 12px;
      }
    }

    .all {
      p {
        font-size: 0.5rem;
      }

      .big-card {
        display: none;
        .all {
          p {
            width: 300px;
            font-size: 0.5rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }

  @media screen and (max-height: 667px) {
    height: 178vh;

    h1 {
      font-size: 1.4rem !important;
    }
  }
  @media screen and (max-height: 590px) {
    height: 220vh;

    h1 {
      font-size: 1.2rem !important;
    }
  }
`;
