import React, { useState } from "react";
import { Container } from "./StyledHeader";
import logo from "../../images/logo.svg";
import wave from "../../images/wave.svg";
import caracters from "../../images/caracters.svg";

const HeaderSection = () => {
  return (
    <Container>
      <nav>
        <h1>
          <img src={logo} />
          Platsel
        </h1>
        <ul></ul>
        <div className="btn">
          <a href="https://www.linkedin.com/in/aksel-louhab">
            <button>Linkedin</button>
          </a>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSfN575MRBRqViKoN0XP0C0ta_UB0mz48O8_nN273IRRoCDFSw/viewform?usp=sf_link">
            <button className="spe">Nous contacter</button>
          </a>
        </div>
      </nav>
      <div className="header">
        <div className="top-header">
          <div className="item-text">
            <h1>
              L'agence web qu'il vous faut <br /> par <span>excellence</span>
            </h1>
            <p>
              PlatSel fusionne à la fois l'Excellence et l'Accessibilité <br />
              pour répondre a vos besoins.
            </p>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfN575MRBRqViKoN0XP0C0ta_UB0mz48O8_nN273IRRoCDFSw/viewform?usp=sf_link">
              <button>Commencer</button>
            </a>
          </div>
          <img src={caracters} className="caracters" />
        </div>
      </div>
      <img src={wave} className="wave" />
    </Container>
  );
};

export default HeaderSection;
