import styled from "styled-components";

export const Container = styled.section`
  width: 100vw;
  height: 100vh;
  background: #191919;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  nav {
    display: flex;
    align-items: center;
    width: 90%;
    position: absolute;
    top: 0;
    padding: 25px;
    justify-content: space-between;
    color: white;

    h1 {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    ul {
      display: flex;
      align-items: center;
      gap: 30px;
    }

    li {
      cursor: pointer;
    }

    li:hover {
      background-color: red;
      background: linear-gradient(
        45deg,
        rgba(252, 100, 66, 1) 0%,
        rgba(240, 35, 96, 1) 35%,
        rgba(179, 3, 102, 1) 65%,
        rgba(115, 8, 90, 1) 100%
      );
      background-size: 100%;
      background-repeat: repeat;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
    }
  }

  span {
    background-color: red;
    background: linear-gradient(
      90deg,
      rgba(252, 100, 66, 1) 0%,
      rgba(240, 35, 96, 1) 35%,
      rgba(179, 3, 102, 1) 65%,
      rgba(115, 8, 90, 1) 100%
    );
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
  }
  .btn {
    display: flex;
    align-items: center;
    gap: 5px;

    button {
      width: 120px;
      height: 35px;
      border: none;
      border-radius: 22px;
      background: transparent;
      color: white;
      font-weight: 300;
      font-size: 0.85rem;
    }
    button.spe {
      background: linear-gradient(
        -15deg,
        rgba(252, 100, 66, 1) 0%,
        rgba(240, 35, 96, 1) 35%,
        rgba(179, 3, 102, 1) 65%,
        rgba(115, 8, 90, 1) 100%
      );
      color: white;
      border-radius: 5px;
      width: 140px;
      height: 50px;
      font-weight: 300;
    }
  }

  .header {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 50px;
    justify-content: center;

    .top-header {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .caracters {
        width: 100vw;
        margin-top: 2%;
        position: relative;
        z-index: 2;
      }
    }

    .item-text {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      gap: 20px;
      position: relative;
      margin-top: 30px;

      img {
      }

      h1 {
        font-size: 3rem;
        color: white;
        line-height: 1.2;
        overflow-y: hidden;
        font-size: clamp(2rem, 3.5vw, 8rem);
        line-height: 1;
        font-weight: 900;
      }

      p {
        color: #fcfcfc;
        font-size: 0.9rem;
        text-align: center;
        opacity: 0.5;
      }

      button {
        width: 140px;
        height: 60px;
        background: white;
        color: #191919;
        border: none;
        border-radius: 5px;
      }
    }
  }
  .wave {
    position: absolute;
    bottom: 0px;
  }

  @media screen and (max-width: 1000px) {
    height: 90vh;
    .header .item-text {
      h1 {
        font-size: 2.5rem;
      }
    }
  }
  @media screen and (max-width: 820px) {
    height: 95vh;
    .header .item-text {
      h1 {
        font-size: 2rem;
      }
      p {
        font-size: 10px;
      }
      button {
        height: 40px;
      }
    }
  }
  @media screen and (max-width: 670px) {
    height: 90vh;
    .header .item-text {
      h1 {
        font-size: 1.7rem;
      }
    }
  }
  @media screen and (max-width: 550px) {
    height: 95vh;
    .header .item-text {
      h1 {
        font-size: 1.5rem;
      }
      p {
        font-size: 8px;
      }
      button {
        width: 120px;
        height: 32px;
        font-size: 0.7rem;
        border-radius: 10px;
      }
    }
  }
  @media screen and (max-width: 440px) {
    height: 98vh;
    .header .item-text {
      h1 {
        font-size: 1.2rem;
      }
      p {
        font-size: 9px;
      }
      button {
        width: 110px;
        height: 38px;
        font-size: 0.7rem;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    display: none;
  }

  @media screen and (max-height: 667px) {
    height: 118vh;
  }
  @media screen and (max-height: 590px) {
    height: 125vh;
  }
`;
