import React, { useState } from "react";
import { Container } from "./StyledContact";

const AccordionSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (openIndex === index) {
      // Si la question est déjà ouverte, fermez-la en cliquant à nouveau
      setOpenIndex(null);
    } else {
      // Sinon, ouvrez la question correspondante
      setOpenIndex(index);
    }
  };

  const accordion = [
    {
      title:
        "Pouvez-vous me montrer des exemples de sites que vous avez créés auparavant ?",
      answer:
        "N'hésitez pas à explorer notre portfolio personnalisé en prenant contact avec notre agent dédié. Nous serons ravis de partager des exemples concrets de sites web que nous avons créés, adaptés aux besoins spécifiques de nos clients. Cette approche interactive nous permettra de mieux comprendre vos attentes et de vous présenter des réalisations pertinentes. Contactez-nous dès maintenant pour entamer cette conversation et commencer à concrétiser votre vision en ligne.",
    },
    {
      title:
        "Quels sont les coûts associés à la création d'un site web et quels services sont inclus dans ce coût ?",
      answer:
        "Les coûts varient en fonction de la complexité du projet. Nous vous fournirons un devis détaillé, et nos services incluent la conception, le développement, les tests et le déploiement.",
    },
    {
      title: "Comment se déroule la communication tout au long du projet ?",
      answer:
        "La communication tout au long du projet est fluide et transparente. Nous organisons des réunions régulières pour vous tenir informé(e) des progrès réalisés et recueillir vos commentaires. De plus, nous restons constamment disponibles, vous permettant de nous contacter à tout moment pour poser des questions ou fournir des directives. Votre implication est essentielle, et nous nous assurons que vous êtes pleinement informé(e) à chaque étape du processus.",
    },
    {
      title:
        "Êtes-vous en mesure de créer des sites web adaptatifs et compatibles avec les appareils mobiles ?",
      answer:
        "Certainement, la conception de sites web adaptatifs est au cœur de notre expertise. Nous nous assurons que nos sites fonctionnent de manière optimale sur tous les appareils, qu'il s'agisse d'ordinateurs de bureau, de smartphones ou de tablettes. Votre site sera non seulement visuellement attrayant, mais offrira également une expérience utilisateur fluide et optimale, quel que soit le dispositif utilisé.",
    },
    {
      title:
        "Quel est le délai estimé pour la réalisation d'un projet de création de site internet ?",
      answer:
        "La concrétisation d'un projet dépend de sa complexité. Un petit projet peut être réalisé en quelques jours, tandis qu'un projet plus important peut nécessiter quelques semaines.",
    },
    {
      title:
        "Proposez-vous des services d'hébergement web ou des recommandations pour un hébergeur fiable ?",
      answer:
        "Oui, nous proposons des services d'hébergement web fiables et pouvons également vous recommander des hébergeurs de confiance.",
    },
  ];

  return (
    <Container>
      <h1>Questions fréquemment posées !</h1>
      <div className="accordion">
        {accordion.map((element, index) => {
          return (
            <div key={index}>
              <div className="accordion-item">
                <div
                  className={`accordion-header ${
                    openIndex === index ? "open" : ""
                  }`}
                  onClick={() => toggleAccordion(index)}
                >
                  <h2>{element.title}</h2>
                  <span>{openIndex === index ? "-" : "+"}</span>
                </div>
                {openIndex === index && (
                  <div className="accordion-content">
                    <p>{element.answer}</p>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export default AccordionSection;
