import "./App.css";
import AffiliationSection from "./components/Affiliate";
import Examples from "./components/Examples";
import HeaderSection from "./components/Header";
import InformationsSections from "./components/Informations";
import AccordionSection from "./components/Accordion";
import ResponsivePage from "./components/ResponsivePage";
import ContactForm from "./components/Form/index.js";
import Chatbot from "./components/ChatBox";

function App() {
  return (
    <>
      <HeaderSection />
      <InformationsSections />
      <Examples />
      <AffiliationSection />
      <ContactForm />
      <AccordionSection />
      {/* <FooterSection /> */}
      <ResponsivePage />
      <Chatbot />
    </>
  );
}

export default App;
