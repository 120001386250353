import React from "react";
import { Container } from "./StyledExamples";

const Examples = () => {
  return (
    <Container>
      <div className="examples-grp">
        <h1>Our projects 🚀</h1>
        <div className="container">
          <div>
            <div className="content">
              <span></span>
            </div>
          </div>
          <div>
            <div className="content">
              <span></span>
            </div>
          </div>
          <div>
            <div className="content">
              <span></span>
            </div>
          </div>
          <div>
            <div className="content">
              <span></span>
            </div>
          </div>
          <div>
            <div className="content">
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Examples;
