import React from "react";
import styled from "styled-components";

const ResponsivePage = () => {
  return (
    <Container>
      <h1>
        La version mobile n'est pas encore disponible veuillez passer sur un
        ordinateur ! 💻
      </h1>
    </Container>
  );
};

export default ResponsivePage;

const Container = styled.section`
  width: 100vw;
  height: 100vh;
  background: #191919;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  h1 {
    color: white;
    width: 90%;
    text-align: center;
    font-weight: 300;
    font-size: 2rem;
  }

  @media screen and (max-width: 544px) {
    h1 {
      font-size: 1.2rem;
    }
  }

  @media screen and (min-width: 1000px) {
    display: none;
  }
`;
