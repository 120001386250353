import React from "react";
import { Container } from "./StyledAffiliate";
import Affiliation from "../../images/afy.svg";

const AffiliationSection = () => {
  return (
    <Container>
      <div className="container">
        <div className="left">
          <img src={Affiliation} />
        </div>
        <div className="right">
          <h1>Devenir Affilié 🔗</h1>
          <p>
            Notre programme d'affiliation vous offre une opportunité lucrative.
            Pour chaque nouveau client que vous nous apportez, vous recevez une
            commission de 10 % sur le montant total qu'ils paient. C'est une
            façon simple et efficace de générer des revenus supplémentaires en
            promouvant nos services de création de sites web et d'applications.
            Rejoignez notre programme d'affiliation aujourd'hui et commencez à
            bénéficier de cette relation gagnant-gagnant.
          </p>
          <button>Devenir affilié</button>
        </div>
      </div>
    </Container>
  );
};

export default AffiliationSection;
