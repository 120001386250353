import styled from "styled-components";
import Aksel from "./nft.png";
import Mode from "./mode.png";
import Crea from "./crea.png";
import Atkou from "./atkou.png";
import Atkou2 from "./atkou2.png";

export const Container = styled.section`
  width: 100vw;
  height: 80vh;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  .examples-grp {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 50px;

    h1 {
      font-size: 2.5rem;
      color: #191919;
    }
  }

  .container {
    width: 100vw;
    display: flex;
    justify-content: center;
    height: 500px;
    gap: 10px;

    > div {
      flex: 0 0 180px;
      border-radius: 0.5rem;
      transition: 0.5s ease-in-out;
      cursor: pointer;
      box-shadow: 1px 5px 15px #191919;
      position: relative;
      overflow: hidden;

      &:nth-of-type(1) {
        background: url(${Aksel}) no-repeat 50% / cover;
      }
      &:nth-of-type(2) {
        background: url(${Mode}) no-repeat 50% / cover;
      }
      &:nth-of-type(3) {
        background: url(${Crea}) no-repeat 50% / cover;
      }
      &:nth-of-type(4) {
        background: url(${Atkou}) no-repeat 50% / cover;
      }
      &:nth-of-type(5) {
        background: url(${Atkou2}) no-repeat 80% / cover;
      }
      &:nth-of-type(6) {
        background: url(${Atkou}) no-repeat 90% / cover;
      }
      &:nth-of-type(7) {
        background: url("https://images.pexels.com/photos/1845208/pexels-photo-1845208.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260")
          no-repeat 50% / cover;
      }

      .content {
        font-size: 1.5rem;
        color: #fff;
        display: flex;
        align-items: center;
        padding: 15px;
        opacity: 0;
        flex-direction: column;
        height: 100%;
        justify-content: flex-end;
        background: rgb(2, 2, 46);
        background: linear-gradient(
          0deg,
          rgba(2, 2, 46, 0.6755077030812324) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        transform: translatey(100%);
        transition: opacity 0.5s ease-in-out, transform 0.5s 0.2s;
        visibility: hidden;

        span {
          display: block;
          margin-top: 5px;
          font-size: 1.2rem;
        }
      }

      &:hover {
        flex: 0 0 250px;
        box-shadow: 1px 3px 15px #191919;
        transform: translatey(-30px);
      }

      &:hover .content {
        opacity: 1;
        transform: translatey(0%);
        visibility: visible;
      }
    }
  }
  @media screen and (max-height: 985px) {
    height: 105vh;

    .container {
      width: 100vw;
      display: flex;
      justify-content: center;
      height: 500px;
      gap: 10px;

      div {
        flex: 0 0 130px;
        border-radius: 0.5rem;
        transition: 0.5s ease-in-out;
        cursor: pointer;
        box-shadow: 1px 5px 15px #191919;
        position: relative;
        overflow: hidden;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;
