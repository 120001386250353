import React, { useEffect } from "react";

function Chatbot() {
  useEffect(() => {
    // Créez un élément script pour charger le script externe
    const script = document.createElement("script");
    script.src = "https://www.chatbase.co/embed.min.js";
    script.chatbotId = "2za-4mmmR1vxKNGM7yk3F";
    script.domain = "www.chatbase.co";
    script.defer = true;

    // Ajoutez le script au corps du document
    document.body.appendChild(script);

    // Retirez le script du corps du document lorsque le composant est démonté
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div id="chatbot-container"></div>;
}

export default Chatbot;
